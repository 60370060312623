import { default as React } from "react"
import {
  Link,
} from "@mui/material"

export default function DessertComponentRecipesByCategory({ pageContext }) {
  const { dessertComponents, dessertComponentIngredients, dessertComponentTypes } = pageContext

  return (
    <>
      <h1>Dessert Component Recipes by Category</h1>
      <h2>Dessert Components</h2>
      <ul>
        { dessertComponents.map(({title, path}) => {
          return (
            <li key={path}>
              <Link href={path} underline="none" color="inherit">
                {title}
              </Link>
            </li>
          )
        })}
      </ul>
      <h2>Dessert Components Recipes by Type</h2>
      <ul>
        { dessertComponentTypes.map(({title, path}) => {
          return (
            <li key={path}>
              <Link href={path} underline="none" color="inherit">
                {title}
              </Link>
            </li>
          )
        })}
      </ul>
      <h2>Dessert Component Recipes by Ingredients</h2>
      <ul>
        { dessertComponentIngredients.map(({title, path}) => {
          return (
            <li key={path}>
              <Link href={path} underline="none" color="inherit">
                {title}
              </Link>
            </li>
          )
        })}
      </ul>
    </>
  )
}
